import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Row, Col} from "react-bootstrap"
import {homepage} from '../../constants/constants'
import LoginForm from '../auth/LoginForm'
import AuthDesign from '../auth/AuthDesign'

class LoginPage extends React.Component {

    constructor(props, context){
        super(props);
        if (props.auth.isAuthenticated) {
            context.router.history.push(homepage)
        }
    }

    render() {
        return (
            <Row className="margin-h-0">
                <Col md={7} className="padding-h-0">
                    <div className="box-full box-theme">
                        <div className="centerizeVH">
                            <AuthDesign/>
                        </div>
                    </div>
                </Col>
                <Col md={5} className="padding-h-0">
                    <div className="box-full-supplement">
                        <div className="centerizeV height-100">
                            <LoginForm/>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}

LoginPage.propTypes = {
    auth: PropTypes.object.isRequired,
}

LoginPage.contextTypes = {
    router: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(
    mapStateToProps,
    null
)(LoginPage);