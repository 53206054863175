import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Row, Col} from "react-bootstrap"
import ResetForm from '../auth/ResetForm'
import InvalidLinkForm from '../auth/InvalidLinkForm'
import {homepage} from '../../constants/constants'
import AuthDesign from '../auth/AuthDesign'
import {validateLink} from '../../actions/AuthActions'

class ResetPasswordPage extends Component {

    constructor(props, context) {
        super(props);
        this.state = {
            isLinkValid: false,
            isLoading: true
        };
        if (props.auth.isAuthenticated) {
            context.router.history.push(homepage)
        }
        this.validateLink(context.router.route.match.params.hash);
    }

    validateLink(link) {
        this.props.validateLink({link: link}).then(
            (res) => this.setState({isLinkValid: res, isLoading: false}),
            (err) => this.setState({isLinkValid: false, isLoading: false})
        );
    }

    render() {
        const form = this.state.isLinkValid ? <ResetForm/> : <InvalidLinkForm/>;
        const message = this.state.isLinkValid ? "Váš odkaz je platný. Nyní si nastavte nové heslo." : "Váš odkaz není platný. Zkontrolujte jeho správnost nebo si zašlete odkaz nový.";

        return (
            <Row className="margin-h-0">
                <Col md={7} className="padding-h-0">
                    <div className="box-full box-theme">
                        <div className="centerizeVH">
                            <AuthDesign
                                text={message}
                            />
                        </div>
                    </div>
                </Col>
                <Col md={5} className="padding-h-0">
                    <div className="box-full-supplement">
                        <div className="centerizeV height-100">
                            {form}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}

ResetPasswordPage.propTypes = {
    validateLink: PropTypes.func.isRequired
};

ResetPasswordPage.contextTypes = {
    router: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        validateLink: bindActionCreators(validateLink, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordPage);