import {combineReducers} from 'redux'

import auth from './auth'
import navigation from './navigation'
import search from './search'
import ingredientsCategories from './ingredientsCategories'
import ingredients from './ingredients'
import {fetchReducer, activeSessionReducer} from './sessions'
import orders from './orders'
import dishes from './dishes'
import units from './units'
import unitCategories from './unitCategories'
import recipes from './recipes'
import warehouse from './warehouse'
import purchaseDetail from './purchase'
import warehouseStatus from './warehouseStatus'
import log from './log'

const rootReducer = combineReducers({
    auth,
    navigation,
    search,
    ingredientsCategories,
    ingredients,
    sessions: fetchReducer,
    activeSession: activeSessionReducer,
    orders,
    dishes,
    units,
    unitCategories,
    recipes,
    purchaseDetail,
    warehouse,
    warehouseStatus,
    log
});

export default rootReducer;