import React from 'react'
import ReactDOM from 'react-dom'

/**
 * Import the stylesheet you want used! Here we just reference
 * the main SCSS file we have in the styles directory.
 */
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'react-day-picker/lib/style.css';
import '../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css';
import './styles/main.scss'

/**
 * Both configureStore and Root are required conditionally.
 * See configureStore.js and Root.js for more details.
 */
import { configureStore } from './store/configureStore'
import { Root } from './containers/Root'

import setAuthorizationToken from './utils/setAuthToken'
import jwtDecode from 'jwt-decode'
import { setCurrentUser } from './actions/AuthActions'

const store = configureStore();

if (localStorage.jwtToken) {
    setAuthorizationToken(localStorage.jwtToken);
    store.dispatch(setCurrentUser(jwtDecode(localStorage.jwtToken)));
}

ReactDOM.render(
  <Root store={store} />,
  document.getElementById('root')
);
