import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {Image} from "react-bootstrap"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {logout} from '../../actions/AuthActions';
import {navigation} from '../../constants/constants'

class Navigation extends React.Component {

    logout(e) {
        e.preventDefault();
        this.props.logout();
    }

    render() {

        const navItems = Object.keys(navigation).map(key => {
                let nav = navigation[key];
                if (nav.navVisible) {
                    if (key == 'separator') {
                        return <hr key={key}/>
                    } else {
                        let active = nav.navigation.identifier == navigation[this.props.navigation].navigation.identifier ? 'active' : '';
                        if(key !== 'log' || this.props.auth.user.master_user){
                            return <li key={key} className={active}>
                                <Link to={nav.navigation.link}
                                      onClick={key == 'logout' ? (e) => this.logout(e) : () => void(0)}
                                >
                                        <span className="nav-label">
                                            {nav.navigation.title}
                                        </span>
                                    <span className="nav-icon">
                                            <FontAwesomeIcon icon={nav.navigation.icon} size="2x"/>
                                        </span>
                                </Link>
                            </li>
                        }else{
                            return null
                        }
                    }
                } else {
                    return false;
                }
            }
        )

        return (
            <div className="navigation hidden-scrollbar">
                <div className="nav-brand">
                    <Image src={require("../../img/logo-medium.png")} className="logo-desktop" responsive/>
                    <Image src={require("../../img/logo-mobile.png")} className="logo-mobile" responsive/>
                </div>
                <nav>
                    <ul>
                        {navItems}
                    </ul>
                </nav>
                <div className="nav-footer">
                    &copy; {(new Date()).getFullYear()} MyWebGrid s.r.o
                </div>
            </div>

        );
    }
}

Navigation.propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    navigation: PropTypes.string.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        navigation: state.navigation
    };
}

export default connect(
    mapStateToProps,
    {logout},
)(Navigation);