import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {Col} from "react-bootstrap"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Creatable from 'react-select/lib/Creatable'

const BoxAddWarehouseCorrection = ({item, units, ingredients, button, active, reset, onSelectIngredient, onChangeIngredient, onSelectUnit, onIngredientCreate}) => {
    const id_unit_category = item.nameValue.id_unit_category;
    const ingredientUnits = units.map((itm, idx) => {
        const enabled = id_unit_category ? id_unit_category == itm.id_unit_category ? true : false : true;
        return <button
            key={idx}
            className={classnames("unit-button btn btn-theme", item.unitValue == itm.id ? "active" : "")}
            title={itm.name}
            onClick={enabled ? () => onSelectUnit(itm.id) : () => void(0)}
            disabled={!enabled}
        >
            {itm.unit}
        </button>
    })

    return (
        <Col sm={12}>
            <div className="box no-hover vertical">
                <div className="box-content">
                    {
                        active ?
                            <div className="reset-box" onClick={reset} title="Zrušit">
                                <FontAwesomeIcon icon="times"/>
                            </div>
                            :
                            null
                    }
                    <div className="box-inputs">
                        <div className="ingredient-name">
                            <Creatable
                                key="select"
                                name="nameValue"
                                placeholder="Položka nákupu"
                                title="Položka nákupu"
                                value={item.nameValue}
                                isDisabled={item.nameValue.value == -1}
                                onChange={(e) => onSelectIngredient(e)}
                                options={ingredients}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                onCreateOption={(e) => onIngredientCreate(e)}
                            />
                        </div>
                        <div className="ingredient-numeric">
                            <input
                                className="form-control"
                                type="number"
                                placeholder="Množství"
                                title="Množství"
                                value={item.quantityValue}
                                onChange={(e) => onChangeIngredient(e)}
                                name="quantityValue"
                                min="0.1"
                                step="0.1"
                            />
                        </div>
                        <div className="ingredient-units">
                            {ingredientUnits}
                        </div>
                    </div>
                </div>
                <div className="box-icons-controls">
                    <div className="box-controls">
                        <div onClick={button.onClick}>{button.label}</div>
                    </div>
                </div>
            </div>
        </Col>
    );
}

BoxAddWarehouseCorrection.propTypes = {
    ingredients: PropTypes.array.isRequired,
    units: PropTypes.array.isRequired,
    button: PropTypes.object.isRequired,
    reset: PropTypes.func.isRequired,
    onSelectIngredient: PropTypes.func.isRequired,
    onChangeIngredient: PropTypes.func.isRequired,
    onSelectUnit: PropTypes.func.isRequired,
    onIngredientCreate: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
}

BoxAddWarehouseCorrection.defaultProps = {
    units: [],
    ingredients: [],
}

export default BoxAddWarehouseCorrection;