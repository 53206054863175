require("babel-polyfill")
require('eventsource-polyfill')
import React, { Component } from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import{ BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom'
import LoginPage from '../components/routes/LoginPage'
import ForgottenPasswordPage from '../components/routes/ForgottenPasswordPage'
import ResetPasswordPage from '../components/routes/ResetPasswordPage'
import ChangePasswordPage from '../components/routes/ChangePasswordPage'
import OrdersPage from '../components/routes/OrdersPage'
import RecipesPage from '../components/routes/RecipesPage'
import WarehousePage from '../components/routes/WarehousePage'
import WarehouseStatusPage from '../components/routes/WarehouseStatusPage'
import SettingsPageCategories from '../components/routes/SettingsPageCategories'
import SessionsPage from '../components/routes/SessionsPage'
import SettingsPageIngredients from '../components/routes/SettingsPageIngredients'
import LogPage from '../components/routes/LogPage'
import ProfilePage from '../components/routes/ProfilePage'
import requireAuth from '../utils/requireAuth'
import {getSessions} from '../actions/SessionsActions'
const { detect } = require('detect-browser')
const browser = detect()

import axios from 'axios'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faLock,
    faLockOpen,
    faUser,
    faTimesCircle as fasTimesCircle,
    faAt,
    faPlus,
    faMinus,
    faSearch,
    faCheck,
    faExclamation,
    faShoppingBasket,
    faWarehouse,
    faCog,
    faSignOutAlt,
    faUtensils,
    faTimes,
    faCircle as fasCircle,
    faExclamationCircle,
    faBarcode,
    faCartArrowDown,
    faClock,
    faHistory,
    faChevronLeft,
    faChevronRight
} from '@fortawesome/free-solid-svg-icons'

import {faTimesCircle, faCircle, faCheckCircle} from '@fortawesome/free-regular-svg-icons'

library.add({
    faLock,
    faLockOpen,
    faUser,
    faAt,
    faPlus,
    faMinus,
    faTimesCircle,
    fasTimesCircle,
    faSearch,
    faCircle,
    fasCircle,
    faCheck,
    faExclamation,
    faShoppingBasket,
    faWarehouse,
    faCog,
    faSignOutAlt,
    faUtensils,
    faTimes,
    faCheckCircle,
    faExclamationCircle,
    faBarcode,
    faCartArrowDown,
    faClock,
    faHistory,
    faChevronLeft,
    faChevronRight
})

class App extends Component {

    constructor(props) {
        super(props)
        axios.defaults.headers.common['Optional'] = "FLKdap6bG6TpveaBEyFu4EkwRUrm9MR88EtpVtEWb2bAWCjj"
        axios.defaults.headers.common['Browser'] = btoa(JSON.stringify(browser))
        props.actions.getSessions()
    }

    render() {
        return process.env.NODE_ENV === 'production' ? (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' render={(props) => <LoginPage/>}/>
                    <Route exact path='/zapomenute-heslo' render={(props) => <ForgottenPasswordPage/>}/>
                    <Route exact path='/nastavit-heslo/:hash' render={(props) => <ResetPasswordPage/>}/>
                    <Route exact path='/zmenit-heslo' component={requireAuth(ChangePasswordPage)}/>
                    <Route exact path='/objednavky' component={requireAuth(OrdersPage)}/>
                    <Route exact path='/recepty' component={requireAuth(RecipesPage)}/>
                    <Route exact path='/sklad' component={requireAuth(WarehousePage)}/>
                    <Route exact path='/sklad/stav-skladu' component={requireAuth(WarehouseStatusPage)}/>
                    <Route exact path='/turnusy' component={requireAuth(SessionsPage)}/>

                    <Route exact path='/nastaveni/kategorie-surovin' component={requireAuth(SettingsPageCategories)}/>
                    <Route exact path='/nastaveni/suroviny' component={requireAuth(SettingsPageIngredients)}/>

                    <Route exact path='/log' component={requireAuth(LogPage)}/>

                    <Route exact path='/profil' component={requireAuth(ProfilePage)}/>
                    <Route component={(props) => <LoginPage/>}/>
                </Switch>
            </BrowserRouter>
        ) : (
            <HashRouter>
                <Switch>
                    <Route exact path='/' render={(props) => <LoginPage/>}/>
                    <Route exact path='/zapomenute-heslo' render={(props) => <ForgottenPasswordPage/>}/>
                    <Route exact path='/nastavit-heslo/:hash' render={(props) => <ResetPasswordPage/>}/>
                    <Route exact path='/zmenit-heslo' component={requireAuth(ChangePasswordPage)}/>
                    <Route exact path='/objednavky' component={requireAuth(OrdersPage)}/>
                    <Route exact path='/recepty' component={requireAuth(RecipesPage)}/>
                    <Route exact path='/sklad' component={requireAuth(WarehousePage)}/>
                    <Route exact path='/sklad/stav-skladu' component={requireAuth(WarehouseStatusPage)}/>
                    <Route exact path='/turnusy' component={requireAuth(SessionsPage)}/>

                    <Route exact path='/nastaveni/kategorie-surovin' component={requireAuth(SettingsPageCategories)}/>
                    <Route exact path='/nastaveni/suroviny' component={requireAuth(SettingsPageIngredients)}/>

                    <Route exact path='/log' component={requireAuth(LogPage)}/>

                    <Route exact path='/profil' component={requireAuth(ProfilePage)}/>
                    <Route component={(props) => <LoginPage/>}/>
                </Switch>
            </HashRouter>
        )
    }
}

App.propTypes = {
    actions: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getSessions,
        }, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);