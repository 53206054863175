import React, { Component } from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {validateEmail} from '../../utils/validations/auth'
import {sendResetEmail} from '../../actions/AuthActions'
import AuthInput from './AuthInput'
import {ToastDanger} from 'react-toastr-basic'
import {Row, Col} from "react-bootstrap"
const { detect } = require('detect-browser');
const browser = detect();

class LoginForm extends Component {

    componentDidUpdate(prevProps, prevState){
        if(this.state.counter != prevState.counter){
            const {errors} = this.state;
            Object.keys(errors).map(key =>
                ToastDanger(errors[key])
            );
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errors: {},
            isLoading: false,
            counter: 0
        };
    }

    isValid() {
        const {errors, isValid} = validateEmail(this.state);
        if (!isValid) {
            this.setState({errors});
        }
        return isValid;
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({counter: this.state.counter + 1});
        if (this.isValid()) {
            this.setState({errors: {}, isLoading: true});
            this.props.sendResetEmail({email: this.state.email, browser: browser.name, os: browser.os, version: browser.version}).then(
                (res) => {
                    if (res !== true) {
                        this.setState({errors: {server: res}, isLoading: false, counter: 0});
                    } else {
                        this.context.router.history.push('/');
                    }
                },
                (err) => this.setState({errors: {server: err}, isLoading: false})
            );
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        const {errors, email, isLoading} = this.state;
        return (
            <form className="auth-form" onSubmit={(e) => this.onSubmit(e)}>
                <h1>Žádost o reset hesla</h1>

                <AuthInput
                    field="email"
                    value={email}
                    label="Email"
                    type="email"
                    onChange={(e) => this.onChange(e)}
                    icon="at"
                    errors={errors}
                />

                <div className="button-row">
                    <Row className="margin-right-0">
                        <Col lg={6} md={7} sm={6} xs={6}>
                            <button className="btn" disabled={isLoading}>Odeslat</button>
                        </Col>
                    </Row>
                </div>
            </form>
        );
    }
}

LoginForm.propTypes = {
    sendResetEmail: PropTypes.func.isRequired
};

LoginForm.contextTypes = {
    router: PropTypes.object.isRequired
}

function mapDispatchToProps(dispatch) {
    return {
        sendResetEmail: bindActionCreators(sendResetEmail, dispatch)
    };
}

export default connect(
    null,
    mapDispatchToProps
)(LoginForm);